<template>
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <vuexy-logo/>
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">
                    {{ i18nT(`You are not authorized!`) }} 🔐
                </h2>
                <p class="mb-0">
                    {{ i18nT(`You don’t have permission to access this page!`) }}
                </p>
                <p class="mb-2">
                    {{i18nT(`You may return to the home screen or you may log-out.`)}}
                </p>
                <b-button
                    variant="primary"
                    class="mb-1 btn-sm-block mr-1"
                    @click="dashboardRoute"
                >
                    {{ i18nT(`Back to Home!`) }}
                </b-button>
                <b-button
                    variant="secondary"
                    class="mb-1 btn-sm-block ml-1"
                    @click="logout"
                >
                    {{ i18nT(`Logout`) }}
                </b-button>
                <b-img
                    fluid
                    :src="imgUrl"
                    alt="Not authorized page"
                />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {BLink, BImg, BButton} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import useAuth from "@/auth/useAuth";

export default {
    components: {
        BLink, BImg, BButton, VuexyLogo,
    },
    data() {
        return {
            downImg: require('@/assets/images/pages/error.svg'),
        }
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.downImg = require('@/assets/images/pages/error.svg')
                return this.downImg
            }
            return this.downImg
        },
    },
    methods: {
        dashboardRoute() {
            //const user = JSON.parse(localStorage.getItem('userData'))
            //return getHomeRouteForLoggedInUser(user ? user.role : null)
            if(useAuth.getUserType()){
                let curSoftware = useAuth.getCurrentSoftware();
                if(curSoftware) {
                    this.$router.push({path: '/' + curSoftware + '/dashboard'});
                }else {
                    this.$router.push({path: '/'});
                }
            }else {
                this.logout();
            }
        },
        logout() {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useAuth.authConfig.storageTokenKeyName);
            localStorage.removeItem(useAuth.authConfig.storageUserTypeKeyName);
            localStorage.removeItem(useAuth.authConfig.storageRefreshTokenKeyName);
            localStorage.removeItem(useAuth.authConfig.storageCurWorkspaceKeyName);
            localStorage.removeItem(useAuth.authConfig.storageWorkspacesKeyName);
            localStorage.removeItem(useAuth.authConfig.storageProfileKeyName);
            localStorage.removeItem(useAuth.authConfig.storageSoftwaresKeyName);
            localStorage.removeItem(useAuth.authConfig.storageCurrentSoftwareKeyName);

            // Remove userData from localStorage
            localStorage.removeItem('userData');

            // Redirect to login page
            this.$router.push({name: 'auth-login'});
        },
    },
}
</script>

<style>
.brand-logo svg {
    min-width: 120px;
    min-height: 50px;
    height: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
